document.addEventListener('turbo:load', function(e) {
  
  /* --------------------------------------------------
                Mobile Navigation
  -------------------------------------------------- */
  const initMobileNav = () => {
    const body = document.body;
    const navToggle = document.getElementById('nav-toggle');
    const navPanel = document.getElementById('nav-panel');
    const navOverlay = document.getElementById('nav-overlay');
    const navClose = document.getElementById('nav-close');
    let isOpen = false;

    const toggleNav = () => {
      isOpen = !isOpen;
      
      navPanel.style.transform = isOpen ? 'translateX(0)' : 'translateX(-100%)';
      body.classList.toggle('overflow-hidden', isOpen);
      navOverlay.classList.toggle('opacity-0', !isOpen);
      navOverlay.classList.toggle('pointer-events-none', !isOpen);
    };

    if (navToggle && navPanel && navOverlay) {
      navToggle.addEventListener('click', () => {
        if (!isOpen) toggleNav();
      });

      navClose.addEventListener('click', () => {
        if (isOpen) toggleNav();
      });

      navOverlay.addEventListener('click', () => {
        if (isOpen) toggleNav();
      });
      
      document.addEventListener('keydown', (e) => {
        if (e.key === 'Escape' && isOpen) toggleNav();
      });
    }
  };

  /* --------------------------------------------------
                Nav Switcher
  -------------------------------------------------- */
  const initNavSwitcher = () => {
    const dropdown = document.querySelector('[data-controller="nav-switcher"]');
    if (!dropdown) return;

    const menu = dropdown.querySelector('[data-nav-switcher-target="menu"]');
    if (!menu) return;

    dropdown.addEventListener('click', (e) => {
      menu.classList.toggle('hidden');
      e.stopPropagation();
    });

    document.addEventListener('click', (e) => {
      if (!dropdown.contains(e.target) && !menu.classList.contains('hidden')) {
        menu.classList.add('hidden');
      }
    });
  };

  /* --------------------------------------------------
                Initialize
  -------------------------------------------------- */
  initMobileNav();
  initNavSwitcher();
});
