/* --------------------------------------------------
                Imports
-------------------------------------------------- */
import { Application } from "@hotwired/stimulus"

/* --------------------------------------------------
                Application
-------------------------------------------------- */
const application = Application.start()
application.warnings = true
application.debug    = false
window.Stimulus      = application

/* --------------------------------------------------
                Export
-------------------------------------------------- */
export { application }
