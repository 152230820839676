document.addEventListener('turbo:load', function(e) {
  
  /* --------------------------------------------------
                Create Maps
  -------------------------------------------------- */
  pgDirectoriesShowCreateMaps = function(){
    let googleMaps = document.getElementById('pages-directories-show-map');
    let zoom = parseInt(googleMaps.getAttribute('data-zoom'));

    if (googleMaps) {
      let firstOfficeWithLocation = directoryOfficesData.find(office => office.latitude && office.longitude);
      let map = new google.maps.Map(googleMaps, { 
        zoom: zoom, 
        mapTypeControl: false, 
        center: { 
          lat: firstOfficeWithLocation.latitude, 
          lng: firstOfficeWithLocation.longitude 
        },
        styles: [
          {
            stylers: [
              { saturation: -100 }
            ]
          }
        ]
      });

      for (const office of directoryOfficesData) {
        if (office.latitude && office.longitude) {
          new google.maps.Marker({ 
            position: new google.maps.LatLng(office.latitude, office.longitude), 
            map: map, 
            icon: {
              url: '/map-pin.png?v=' + new Date().getTime(),
              scaledSize: new google.maps.Size(32, 32),
            }
          });
        }
      }
    }
  }
});

/* --------------------------------------------------
        Create Maps When Page Ready
-------------------------------------------------- */
pgDirectoriesShowCreateMapsWhenPageReady = function(){
  if (typeof pgDirectoriesShowCreateMaps == 'undefined') {
    document.addEventListener('turbo:load', function(e) {
      pgDirectoriesShowCreateMaps()
    })
  } else {
    pgDirectoriesShowCreateMaps()
  }
}
