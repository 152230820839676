document.addEventListener('turbo:load', function(e) {

  /* --------------------------------------------------
                Create Maps
  -------------------------------------------------- */
  pgJobsViewCreateMaps = function(){
    let googleMaps = document.getElementById('pages-jobs-view-map');

    if (googleMaps) {
      let firstJobWithLocation = jobsData.find(job => job.location_lat && job.location_lng);
      let map = new google.maps.Map(googleMaps, { zoom: 7, mapTypeControl: false, center: { lat: firstJobWithLocation.location_lat, lng: firstJobWithLocation.location_lng } });
      let mapInfoWindow = new google.maps.InfoWindow;
      let mapMarkers = [];

      for (const [index, job] of jobsData.entries()) {
        if (job.location_lat && job.location_lng) {
          let mapMarker = new google.maps.Marker({ 
            position: new google.maps.LatLng(job.location_lat ,job.location_lng), 
            map: map, 
            title: job.name,
            icon: {
              url: '/map-pin.png?v=' + new Date().getTime(),
              scaledSize: new google.maps.Size(32, 32),
            }
          });
          mapMarkers.push(mapMarker);
          google.maps.event.addListener(mapMarker, 'click', function() {
            mapInfoWindow.setContent(
              '<div class="flex flex-col gap-1">' +
                '<div>' +
                  '<div class="text-sm font-semibold">' + job.name + '</div>' +
                  '<div class="text-xs text-gray-500 font-medium">' + job.location + '</div>' +
                '</div>' +
                '<a href="/pages/jobs/' + job.id + '/view/show" class="block text-xs font-semibold focus:outline-none">View Project &rarr;</a>' +
              '</div>'
            );
            mapInfoWindow.setOptions({ maxWidth: 240 });
            mapInfoWindow.open(map, this);
          });
          document.querySelector('[data-js="job-card"][data-index="' + index + '"]').addEventListener('mouseenter', (e) => {
            mapInfoWindow.close();
            google.maps.event.trigger(mapMarkers[index], 'click');
          });
        }
      }
    }
  }
});

/* --------------------------------------------------
        Create Maps When Page Ready
-------------------------------------------------- */
pgJobsViewCreateMapsWhenPageReady = function(){
  if (typeof pgJobsViewCreateMaps == 'undefined') {
    document.addEventListener('turbo:load', function(e) {
      pgJobsViewCreateMaps()
    })
  } else {
    pgJobsViewCreateMaps()
  }
}
