['turbo:frame-load', 'turbo:load'].forEach(function(ev) {
  document.addEventListener(ev, function(e) {

    /* --------------------------------------------------
                  Init
    -------------------------------------------------- */
    const cmpModalInit = function(){
      const modals = document.querySelectorAll('[data-js="modal-container"]');
      
      modals.forEach(modal => {
        const modalContent = modal.querySelector('[data-js="modal"]');
        const openTriggers = document.querySelectorAll(`[data-js="modal-open"][data-id="${modal.id.replace('modal-', '')}"]`);
        const closeTriggers = modal.querySelectorAll('[data-js="modal-close"]');
        const backdrop = modal.querySelector('[data-js="modal-backdrop"]');
        const modalScroll = modal.querySelector('[data-js="modal-scroll"]');

        function showModal() {
          document.body.style.overflow = 'hidden';
          modal.classList.remove('hidden');
          modal.setAttribute('aria-hidden', 'false');
          modalContent.focus();
          modal.addEventListener('keydown', handleEscapeKey);
        }

        function hideModal() {
          modal.classList.add('hidden');
          modal.setAttribute('aria-hidden', 'true');
          document.body.style.overflow = 'auto';
          modal.removeEventListener('keydown', handleEscapeKey);
        }

        function handleEscapeKey(e) {
          if (e.key === 'Escape') {
            hideModal();
          }
        }

        function handleBackdropClick(e) {
          if (e.target === backdrop || e.target === modalScroll) {
            hideModal();
          }
        }

        openTriggers.forEach(trigger => {
          trigger.addEventListener('click', (e) => {
            e.preventDefault();
            showModal();
          });
        });

        closeTriggers.forEach(trigger => {
          trigger.addEventListener('click', (e) => {
            e.preventDefault();
            hideModal();
          });
        });

        backdrop.addEventListener('click', handleBackdropClick);
        modalScroll.addEventListener('click', handleBackdropClick);
      });
    }

    /* --------------------------------------------------
                  Functions
    -------------------------------------------------- */
    cmpModalInit();
  });
})
