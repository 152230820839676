["turbo:frame-load", "turbo:load"].forEach(function (ev) {
  document.addEventListener(ev, function (e) {
    /* --------------------------------------------------
                  Init
    -------------------------------------------------- */
    const vdStripeInit = function () {
      let stripeElementsEl = document.getElementById("vendors-stripe-elements");

      if (stripeElementsEl) {
        const options = {
          amount: 10000,
          currency: "usd",
          mode: "payment",
        };

        let stripeElements = stripe.elements(options);
        let stripeCard = stripeElements.create("payment");
        let stripeElementsErrors = document.getElementById("vendors-stripe-elements-errors");
        let stripeForm = stripeElementsEl.closest("form");
        let stripeFormSubmit = stripeForm.querySelector('button[type="submit"]');

        stripeCard.mount("#" + stripeElementsEl.id);
        window.stripeFormSubmit = async function (e) {
          e.preventDefault();
          stripeFormSubmit.disabled = true;
          let newCardElement = document.querySelector(
            "#job_transaction_selected_payment_method_-1"
          );
          let newCard = !newCardElement || newCardElement.checked;

          if (newCard) {
            const { error: submitError } = await stripeElements.submit();
            if (submitError) {
              handleError(submitError);
              return;
            }
          }

          let csrfToken = document.querySelector(
            'meta[name="csrf-token"]'
          ).content;
          const checkedCategories = document.querySelectorAll(
            '[data-job-transaction-target="category"]:checked'
          );
          const checkedCategoriesList = Array.from(checkedCategories).map(
            (category) => category.value
          );

          // Create the PaymentIntent and obtain clientSecret
          const res = await fetch(
            document.querySelector("[data-job-transaction-url-value]").dataset[
              "jobTransactionUrlValue"
            ],
            {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "X-CSRF-Token": csrfToken,
              },
              method: "POST",
              body: JSON.stringify({ items: checkedCategoriesList }),
            }
          );
          const { clientSecret } = await res.json();

          let error;
          let paymentIntent;
          
          // Use the clientSecret and Elements instance to confirm the setup
          if (newCard) {
            ({ error, paymentIntent } = await stripe.confirmPayment({
              clientSecret: clientSecret,
              elements: stripeElements,
              confirmParams: {
                return_url: "https://localhost.com:3000/pages/jobs",
              },
              redirect: "if_required",
            }));
          } else {
            const selectedRadioButton = document.querySelector(
              '[name="job_transaction[selected_payment_method]"]:checked'
            );
            const firstPaymentMethod = document.querySelector(
              '[name="job_transaction[selected_payment_method]"]'
            );
            const paymentMethodId =
              (selectedRadioButton && selectedRadioButton.value) ||
              (firstPaymentMethod && firstPaymentMethod.value);
            ({ error, paymentIntent } = await stripe.confirmPayment({
              clientSecret: clientSecret,
              confirmParams: {
                payment_method: paymentMethodId,
                return_url: "https://localhost.com:3000/pages/jobs",
              },
              redirect: "if_required",
            }));
          }

          if (error) {
            handleError(error);
          } else if (paymentIntent && paymentIntent.status === "succeeded") {
            stripeElementsErrors.classList.add('hidden');
            stripeTokenHandler(paymentIntent);
          }
        };

        const handleError = function (error) {
          stripeFormSubmit.disabled = false;
          stripeElementsErrors.textContent = error.message;
          stripeElementsErrors.classList.remove('hidden');
          Bugsnag.notify(new Error(error.message));
        };
        stripeForm.addEventListener("submit", window.stripeFormSubmit);

        function stripeTokenHandler(token) {
          let stripeHiddenInput = document.createElement("input");
          stripeHiddenInput.setAttribute("type", "hidden");
          stripeHiddenInput.setAttribute("name", "stripeToken");
          stripeHiddenInput.setAttribute("value", token.id);
          stripeForm.appendChild(stripeHiddenInput);
          stripeForm.submit();
        }
      }
    };

    /* --------------------------------------------------
                  Functions
    -------------------------------------------------- */
    vdStripeInit();
  });
});
