/* --------------------------------------------------
                Imports
-------------------------------------------------- */
import { Controller } from "@hotwired/stimulus"

/* --------------------------------------------------
                Nav Upgrade Controller
-------------------------------------------------- */
export default class extends Controller {
  static targets = [ "banner" ]

  hideUpgradeBanner() {
    fetch('/api/v1/users/hide_nav_upgrade_banner', { headers: { accept: "application/json" }, method: "POST" })
      .then(() => {
        this.bannerTarget.classList.add('hidden')
      })
  }
}
