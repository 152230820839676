console.log("card-selector controller loaded")
import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="card-selector"
export default class extends Controller {
  static targets = ["stripeElements"]

  cardChanged(event) {
    if (event.target.value === "-1") {
      this.stripeElementsTarget.classList.remove("hidden")
    } else {
      this.stripeElementsTarget.classList.add("hidden")
    }
  }
}
