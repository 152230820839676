import Choices from 'choices.js';
['turbo:frame-load', 'turbo:load'].forEach(function(ev) {
  document.addEventListener(ev, function(e) {
    
    /* --------------------------------------------------
                  Load Users
    -------------------------------------------------- */
    window.loadUsers = function(choice, value, planroomId){
      fetch(`/api/v1/users?q=${value}&planroom_id=${planroomId}`)
        .then(response => response.json())
        .then(data => {
          choice.setChoices(data, 'id', 'display_name', true);
        })
        .catch(error => {
          callback([], 'Failed to load results');
        });

    }

    /* --------------------------------------------------
                  Init
    -------------------------------------------------- */
    const vdChoicesInit = function(){
      let choices = document.querySelectorAll('[data-js="choices"]');

      Array.prototype.forEach.call(choices, function(el, i) {
        let choice = new Choices(el, {
          searchPlaceholderValue: "Type to search...",
          removeItemButton: el.hasAttribute('data-choices-remove-item-button') ? true : false,
          searchEnabled: el.hasAttribute('data-choices-search-enabled') ? true : false,
          classNames: { containerInner: 'choices__inner' },
          noResultsText: 'No matches found',
          noChoicesText: 'Start typing to search...'
        });
        if (el.dataset.choicesLoadFunction){
          choice.input.element.addEventListener('keyup', function(e){
            let timeout;
            clearTimeout(timeout);
            timeout = setTimeout(() => {
              window[el.dataset.choicesLoadFunction](choice, e.target.value, el.dataset.planroomId || '');
            }, 300);
          });
          window[el.dataset.choicesLoadFunction](choice, '', el.dataset.planroomId || '');
        }

        choice.passedElement.element.addEventListener('choice',
          function(e) {
            if (el.hasAttribute('data-choices-switch')){
              let switchee = document.getElementById('choice-switchee-' + e.target.name + "-" + e.detail.choice.value);
              let switchees = document.querySelectorAll('[id^="choice-switchee-' + e.target.name + '"]');
              Array.prototype.forEach.call(switchees, function(el, i) {
                el.style.display = "none";
              });
              if (switchee) { switchee.style.display = "block"; }
            }
          },
        false);
      });
    }

    /* --------------------------------------------------
                  Jumper Init
    -------------------------------------------------- */
    const vdChoicesJumperInit = function(){
      let jumpers = document.querySelectorAll('[data-choices-jumper="true"]');

      Array.prototype.forEach.call(jumpers, function(el, i) {
        el.addEventListener('choice', (e) => {
          var searchParams = e.detail.choice.value.split('=');
          var url = new URL(window.location);
          url.searchParams.set(searchParams[0], searchParams[1]);
          window.location = url;
        });
      });
    }

    /* --------------------------------------------------
                  Functions
    -------------------------------------------------- */
    vdChoicesInit();
    vdChoicesJumperInit();
  });
})
