/* --------------------------------------------------
                Imports
-------------------------------------------------- */
import { Controller } from "@hotwired/stimulus";

/* --------------------------------------------------
                Toggle Controller
-------------------------------------------------- */
export default class extends Controller {
  static targets = ["toggleable"];
  static values = {
    open: { type: Boolean, default: false },
  };

  toggle(event) {
    this.openValue = !this.openValue;
    this.showHide();
  }

  // Sets open to value of checkbox or radio
  toggleInput(event) {
    this.openValue = event.target.checked;
    this.showHide();
  }

  hide() {
    this.openValue = false;
    this.showHide();
  }

  show() {
    console.log("show");
    this.openValue = true;
    this.showHide();
  }

  showHide() {
    this.toggleableTargets.forEach((target) => {
      if (this.openValue) {
        target.classList.remove("hidden");
      } else {
        target.classList.add("hidden");
      }
    });
  }
}
