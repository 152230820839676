document.addEventListener('turbo:load', function(e) {

  /* --------------------------------------------------
                Init
  -------------------------------------------------- */
  const cmpTabsInit = function(){
    let tabs = document.querySelectorAll('[data-tab-item]');
    let tabsPanels = document.querySelectorAll('[data-tabs-panel]');
    
    Array.prototype.forEach.call(tabs, function(el, i) {
      el.addEventListener('click', (e) => {
        Array.prototype.forEach.call(tabs, function(el, i) {
          el.setAttribute('aria-selected', 'false');
        });
        Array.prototype.forEach.call(tabsPanels, function(el, i) {
          el.classList.add('hidden');
        });
        e.target.setAttribute('aria-selected', 'true');
        let panel = document.querySelector('[data-tabs-panel="' + el.dataset.tabItem + '"]');
        if (panel) {
          panel.classList.remove('hidden');
          panel.setAttribute('aria-selected', 'true');
        }
        e.preventDefault();
      });
    });
  }

  const nextTabButtonsInit = function(){
    let nextTabButtons = document.querySelectorAll('[data-js="tab-next"]');

    Array.prototype.forEach.call(nextTabButtons, function(el, i) {
      el.addEventListener('click', (e) => {
        let nextPanel = el.closest('[data-tabs-panel]').nextElementSibling;
        let nextTab = document.querySelector(`[data-tab-item="${nextPanel.dataset.tabsPanel}"]`);
        
        if (nextTab) {
          nextTab.click();
          window.scrollTo({
            top: nextPanel.offsetTop,
            behavior: 'smooth'
          });
        }
        e.preventDefault();
      });
    });
  }

  /* --------------------------------------------------
                Functions
  -------------------------------------------------- */
  cmpTabsInit();
  nextTabButtonsInit();
});
