document.addEventListener('turbo:load', function(e) {

  /* --------------------------------------------------
                Checkbox Toggle Init
  -------------------------------------------------- */
  const cmpCheckboxToggleInit = function(){
    let toggles = document.querySelectorAll('[data-js="checkbox-toggle"]');

    Array.prototype.forEach.call(toggles, function(el, i) {
      let checkbox = el.querySelector('input[type="checkbox"]');

      checkbox.addEventListener('change', (e) => {
        let togglees = document.querySelectorAll('[data-checkbox-togglee="' + checkbox.id + '"]');
        
        Array.prototype.forEach.call(togglees, function(el, i) {
          el.classList.toggle('hidden');
        });
      });
    });
  }

  /* --------------------------------------------------
                Functions
  -------------------------------------------------- */
  cmpCheckboxToggleInit();
});
