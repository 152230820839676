/* --------------------------------------------------
                Imports
-------------------------------------------------- */
import { Controller } from "@hotwired/stimulus"

/* --------------------------------------------------
                Search Controller
-------------------------------------------------- */
export default class extends Controller {
  static targets = [ "input" ]

  clear() {
    this.inputTarget.value = ""
    this.element.submit()
  }
}
