/* --------------------------------------------------
                Imports
-------------------------------------------------- */
import { Controller } from "@hotwired/stimulus"

/* --------------------------------------------------
                Sortable Table Controller
-------------------------------------------------- */
export default class extends Controller {
  static targets = [ "header" ]

  connect() {
    this.queryParams = new URLSearchParams(window.location.search);
    this.sortCriteria = this.queryParams.get("sort")
    if (this.sortCriteria !== null) {
      this.applyCssClassToElement()
    }
  }

  sort(e) {
    let column = e.target.dataset.field
    if (column !== undefined) {
      if (this.sortCriteria == null) { // No current sort order
        this.queryParams.set("sort", `${column}:asc`);
      } else {
        if (this.sortCriteria.match(column)) { // If the order needs to be reversed
          let newOrder = this.sortCriteria.match("asc") ? "desc" : "asc"
          this.queryParams.set("sort", `${column}:${newOrder}`);
        } else { // If the table needs to be ordered by a different key
          this.queryParams.set("sort", `${column}:asc`);
        }
      }
      window.location.search = this.queryParams
    }
  }

  applyCssClassToElement() {
    let sortArray = this.sortCriteria.split(":")
    let field = sortArray[0]
    let sortDirection = sortArray[1]
    let wantedElement = Array.prototype.find.call(this.headerTargets, function(el) {
      if (el.dataset.field == field) {
        return el
      }
    })
    if (!!wantedElement) {
      wantedElement.classList.add(`sorted-${sortDirection}`)
    }
  }
}
