import {delegate} from 'tippy.js';

document.addEventListener('turbo:load', function(e) {

  /* --------------------------------------------------
                Full Calendar Init
  -------------------------------------------------- */
  const pgCalendarFullCalendarInit = function(){
    let calendarId = 'pages-calendar-fullcalendar';
    let calendarItem = '.fc-day-grid-event';
    let calendarEl = document.getElementById(calendarId);

    if (calendarEl) {
      let calendar = new FullCalendar.Calendar(calendarEl, {
        timeZone: window.planRoom.timeZone,
        initialView: 'dayGridMonth',
        headerToolbar: { right: 'dayGridMonth,listMonth today prev,next' },
        height: 'parent',
        weekends: false,
        buttonText: {
          today: 'Today',
          dayGridMonth: 'Month',
          listMonth: 'List'
        },
        dayMaxEvents: true,
        eventSources: [{
          url: '/pages/calendar/jobs'
        }],
        displayEventTime: false
      });
      calendar.render();
      
      const calendarLegend = document.getElementById('pages-calendar-fullcalendar-legend');
      const calendarToolbar = calendarEl.querySelector('.fc-header-toolbar');
      const calendarToolbarChunk = '.fc-toolbar-chunk:last-child';

      if (calendarLegend && calendarToolbar) {
        calendarLegend.classList.remove('hidden');
        const lastChunk = calendarToolbar.querySelector(calendarToolbarChunk);
        lastChunk.insertBefore(calendarLegend, lastChunk.firstChild);
      }
      
      delegate('#' + calendarId, {
        target: calendarItem,
        content: (element) => element.textContent
      });
    }
  }

  /* --------------------------------------------------
                Functions
  -------------------------------------------------- */
  pgCalendarFullCalendarInit();
});
