/* ----------------------------------------------------
              Requires
----------------------------------------------------- */
require("@rails/activestorage").start();
require("@rails/ujs").start()
require("trix");
require("@rails/actiontext");

/* ----------------------------------------------------
              Imports
----------------------------------------------------- */
import "@hotwired/turbo-rails";
Turbo.session.drive = false

/* ----------------------------------------------------
              Stimulus
----------------------------------------------------- */
require("./controllers/index")

/* ----------------------------------------------------
              Vendor
----------------------------------------------------- */
require("../packs/vendor/choices");
require("../packs/vendor/filestack");
require("../packs/vendor/stripe");
require("../packs/vendor/tippy");
require("../packs/vendor/trix");

/* ----------------------------------------------------
              Components
----------------------------------------------------- */
require("../packs/components/box");
require("../packs/components/checkbox");
require("../packs/components/confetti");
require("../packs/components/field");
require("../packs/components/form");
require("../packs/components/input");
require("../packs/components/modal");
require("../packs/components/nav");
require("../packs/components/radios");
require("../packs/components/select");
require("../packs/components/slider");
require("../packs/components/sticky");
require("../packs/components/tabs");

/* ----------------------------------------------------
              Pages
----------------------------------------------------- */
require("../packs/pages/calendar/index");
require("../packs/pages/directories/show");
require("../packs/pages/home/index");
require("../packs/pages/job_changes/job_change");
require("../packs/pages/job_changes/update");
require("../packs/pages/job_files/view/show");
require("../packs/pages/job_transactions/shared/new");
require("../packs/pages/jobs/view/index");
require("../packs/pages/jobs/form");
require("../packs/pages/notes/note");
require("../packs/pages/notes/create");
require("../packs/pages/notes/destroy");
require("../packs/pages/notes/update");
