['turbo:frame-load', 'turbo:load'].forEach(function(ev) {
  document.addEventListener(ev, function(e) {

    /* --------------------------------------------------
                  Toggle Init
    -------------------------------------------------- */
    const pgJobTransactionsNewToggleInit = function(){
      const body = document.body;
      const panel = document.getElementById('pages-job_transactions-new-panel');
      const overlay = document.getElementById('pages-job_transactions-new-overlay');
      const close = document.getElementById('pages-job_transactions-new-close');
      const newOpens = document.querySelectorAll('[data-js="pages-job_transactions-new-open"]');
      let isOpen = false;

      const togglePanel = () => {
        isOpen = !isOpen;

        panel.classList.toggle('translate-x-full', !isOpen);
        body.classList.toggle('overflow-hidden', isOpen);
        overlay.classList.toggle('opacity-0', !isOpen);
        overlay.classList.toggle('pointer-events-none', !isOpen);
        overlay.classList.toggle('hidden', !isOpen);
      };

      if (panel && overlay) {
        newOpens.forEach(newOpen => {
          newOpen.addEventListener('click', (e) => {
            if (!isOpen) togglePanel();
            e.preventDefault();
          });
        });

        close.addEventListener('click', () => {
          if (isOpen) togglePanel();
        });

        overlay.addEventListener('click', () => {
          if (isOpen) togglePanel();
        });
        
        document.addEventListener('keydown', (e) => {
          if (e.key === 'Escape' && isOpen) togglePanel();
        });
      }
    }

    /* --------------------------------------------------
                  Stripe Init
    -------------------------------------------------- */
    const pgJobTransactionsNewStripeInit = function(){
      let form = document.getElementById('new_job_transaction');

      if (form) {
        let formSubmit = form.querySelector('button[type="submit"]');
        let checkboxDeliveryCategories = document.querySelectorAll('[name="job_transaction[delivery_categories][]"]');
        let checkboxDeliveryCategoriesChecked = '[name="job_transaction[delivery_categories][]"]:checked';
        let checkboxDigitalCategoryCharge = document.getElementById('job_transaction_delivery_categories_digital_category_charge');
        let checkboxPrintCategoryCharge = document.getElementById('job_transaction_delivery_categories_print_category_charge');
        let checkboxPrintCategoryDeposit = document.getElementById('job_transaction_delivery_categories_print_category_deposit');
        let checkboxJobContracts =  document.querySelectorAll('[name="job_transaction[job_transaction_job_contracts_attributes][0][job_contract][]"]');
        let checkboxJobContractsChecked = '[name="job_transaction[job_transaction_job_contracts_attributes][0][job_contract][]"]:checked';
        let sectionCategoryCharge = document.getElementById('job_transaction_delivery_category_charge');
        let sectionCategoryDeposit = document.getElementById('job_transaction_delivery_category_deposit');
        let sectionPrintCategoryDeposit = document.getElementById('job_transaction_delivery_print_category_deposit');
        let sectionPrintCategoryShipping = document.getElementById('job_transaction_delivery_print_category_charge');

        form.removeEventListener('submit', window.stripeFormSubmit);
        Array.prototype.forEach.call(checkboxDeliveryCategories, function(el, i) {
           el.addEventListener('click', (e) => {
             toggleFormEventListener(e);
             toggleFormSubmit();
           });
        });
        Array.prototype.forEach.call(checkboxJobContracts, function(el, i) {
           el.addEventListener('click', (e) => {
             toggleFormSubmit();
           });
        });

        const toggleFormEventListener = function(e){
          if (sectionCategoryCharge) {
            if ((checkboxDigitalCategoryCharge && checkboxDigitalCategoryCharge.checked) || (checkboxPrintCategoryCharge && checkboxPrintCategoryCharge.checked)) {
              sectionCategoryCharge.classList.remove('hidden');
              form.addEventListener('submit', window.stripeFormSubmit);
            } else if ((checkboxDigitalCategoryCharge && !checkboxDigitalCategoryCharge.checked) || (checkboxPrintCategoryCharge && !checkboxPrintCategoryCharge.checked)) {
              sectionCategoryCharge.classList.add('hidden');
              form.removeEventListener('submit', window.stripeFormSubmit);
            }
            if (checkboxPrintCategoryCharge && checkboxPrintCategoryCharge.checked) {
              sectionPrintCategoryShipping.classList.remove('hidden');
            }
            if (checkboxPrintCategoryCharge && !checkboxPrintCategoryCharge.checked) {
              sectionPrintCategoryShipping.classList.add('hidden');
            }
          }
          if (sectionCategoryDeposit) {
            if (checkboxPrintCategoryDeposit && checkboxPrintCategoryDeposit.checked) {
              sectionCategoryDeposit.classList.remove('hidden');
              sectionPrintCategoryDeposit.classList.remove('hidden');
            } else {
              sectionCategoryDeposit.classList.add('hidden');
              sectionPrintCategoryDeposit.classList.add('hidden');
            }
          }
        }
        const toggleFormSubmit = function(){
          if (document.querySelectorAll(checkboxDeliveryCategoriesChecked).length > 0 &&
              document.querySelectorAll(checkboxJobContractsChecked).length > 0) {
            formSubmit.disabled = false;
            formSubmit.classList.remove('opacity-50', 'pointer-events-none', 'text-select-none');
          } else {
            formSubmit.disabled = true;
          }
        }
      }
    }

    /* --------------------------------------------------
                  Functions
    -------------------------------------------------- */
    pgJobTransactionsNewToggleInit();
    pgJobTransactionsNewStripeInit();
  });
})
