document.addEventListener('turbo:load', function(e) {

  /* --------------------------------------------------
                Radios Switch Init
  -------------------------------------------------- */
  const cmpRadiosSwitchInit = function() {
    let switches = document.querySelectorAll('[data-js="radios-switch"]');

    Array.prototype.forEach.call(switches, function(el, i) {
      let input = el.querySelector('input');
      
      input.addEventListener('change', (e) => {
        let switchee = document.getElementById('radios-switchee-' + input.name + "-" + input.value);
        let switchees = document.querySelectorAll('[id^="radios-switchee-' + input.name + '"]');

        Array.prototype.forEach.call(switchees, function(el, i) {
          el.classList.add('hidden');
        });
        if (switchee) { switchee.classList.remove('hidden'); }
      });
    });
  }

  /* --------------------------------------------------
                Functions
  -------------------------------------------------- */
  cmpRadiosSwitchInit();
});
