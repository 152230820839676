document.addEventListener('turbo:load', function () {
  const vdTrixInit = function () {
    const trixEditors = document.querySelectorAll('trix-editor');
    trixEditors.forEach(editor => {
      const toolbar = editor.toolbarElement;

      if (toolbar) {
        const buttonGroups = toolbar.querySelectorAll(".trix-button-group");
        buttonGroups.forEach(group => {
          const groupType = group.getAttribute("data-trix-button-group");
          if (groupType === "file-tools") {
            group.remove();
          }
          if (groupType === "block-tools") {
            const headerButton = group.querySelector('[data-trix-attribute="heading1"]');
            if (headerButton) {
              headerButton.remove();
            }
          }
        });
      }
    });
  };

  vdTrixInit();
});
