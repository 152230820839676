document.addEventListener('turbo:load', function(e) {

  /* --------------------------------------------------
                Note
  -------------------------------------------------- */
  pgNotesInit = function(){
    let noteSelector = '[data-js="note"]';
    let noteEdits = document.querySelectorAll(`${noteSelector} [data-js="note-edit"]`);
    let noteDetailsFormCancels = document.querySelectorAll(`${noteSelector} [data-js="note-cancel"]`);
    let noteContent = '[data-js="note-content"]';
    let noteForm = '[data-js="note-form"]';

    Array.prototype.forEach.call(noteEdits, function(el, i) {
      el.addEventListener('click', (e) => {
        const noteEl = el.closest(noteSelector);
        noteEl.querySelector(noteContent).classList.add('hidden');
        noteEl.querySelector(noteForm).classList.remove('hidden');
        e.preventDefault();
      });
    });
    Array.prototype.forEach.call(noteDetailsFormCancels, function(el, i) {
      el.addEventListener('click', (e) => {
        const noteEl = el.closest(noteSelector);
        noteEl.querySelector(noteContent).classList.remove('hidden');
        noteEl.querySelector(noteForm).classList.add('hidden');
        e.preventDefault();
      });
    });
  }

  /* --------------------------------------------------
                Functions
  -------------------------------------------------- */
  pgNotesInit();
});
